<script>
import AxiosService from "../axiosServices/AxiosService";
import { mapGetters } from "vuex";

import Deliveries from "../components/Surveys/Deliveries.vue";
export default {
  name: "Surveys",
  components: {
    Deliveries,
  },

  data() {
    return {
      sceltaMultipla: false,

      ad_ref: 1,

      listaOpzioni: [],

      view_lang: null,
      currentCompany: null,

      loadingItems: false,
      loadingSurveyTypes: false,
      loadingKpis: false,
      loadingSaveData: false,
      loadingDeleteItem: false,

      items: [],
      surveyTypes: [],
      companyKpisList: [],

      fields: [
        // { key: "id", label: "ID" },
        { key: "id_tipi", label: "Type" },
        { key: "t_nome", label: "Name" },
        { key: "id_kpi_esecuzione", label: "Accomplished survey done kpi" },
        { key: "id_kpi_ricezione", label: "Accomplished survey received kpi" },
        { key: "domande", label: "Questions" },
        { key: "actions", label: "Actions" },
      ],

      currentPage: 1,
      perPage: 10,
      filter: null,
      totalRows: 0,

      tempItem: {},

      selectedSurveyTypes: [], // Updated to handle multiple selections

      modelItem: {
        id: 0,
        t_nome: {},
        id_tipi: [], // Updated to be an array
        id_kpi_esecuzione: 0,
        id_kpi_ricezione: 0,
        domande: [],
      },
      modelOption: {
        id: 0,
        id_question: 0,
        valoreIntero: 0,
        valoreTestuale: "",
      },
      modelQuestion: {
        id: 0,
        id_survey: 0,
        id_kpi: 0,
        tipo_form: "Gradiente numerico",
        t_domanda: {},
        rateDa: 0,
        t_labelDa: {},
        rateA: 0,
        t_labelA: {},
        nota_presente: false,
        nota_obbligatoria: false,
        steps: [],
        opzioniSiONo: [
          {
            t_label: {
              "it-IT": "Si",
              "en-EN": "Yes",
              "es-ES": "Si",
            },
            value: 2,
          },
          {
            t_label: {
              "it-IT": "No",
              "en-EN": "No",
              "es-ES": "No",
            },
            value: 1,
          },
        ],
      },
      syrveyTypeIcons: {
        "Downward feedback": "arrow-bar-down",
        "Upward feedback": "arrow-bar-up",
        "Peer feedback": "people-fill",
        "Self Review": "person-fill",
      },

      useDefaultSteps: false,
      defaultSteps: {
        rateDa: 1,
        t_labelDa: {
          "it-IT": "Male",
          "en-EN": "Bad",
          "es-ES": "Mal",
        },
        rateA: 5,
        t_labelA: {
          "it-IT": "Bene",
          "en-EN": "Good",
          "es-ES": "Bien",
        },
      },
    };
  },
  created() {
    this.view_lang = this.default_lang.value || this.default_lang;
    this.tempItem = { ...this.modelItem };
  },
  mounted() {
    this.currentCompany = this.currentCompanyFromStore;
    this.getSurveyTypes();
  },

  computed: {
    ...mapGetters(["languages"]),
    ...mapGetters(["default_lang"]),
    currentCompanyFromStore() {
      return this.$store.getters.currentCompany;
    },
  },

  watch: {
    currentCompanyFromStore() {
      this.currentCompany = this.currentCompanyFromStore;
      this.getCompanyKpi();
      this.loadItems();
    },
  },

  methods: {
    aggiungiOpzione(q) {
      if (!q.steps) {
        q.steps = [];
      }

      console.log("steps ", q);

      q.steps.push({
        t_label: { "it-IT": "", "en-EN": "", "es-ES": "" },
        value: null,
      });
      this.tempItem.tipo_form = "";
      this.tempItem.tipo_form = "Gradiente testuale";

      this.ad_ref++;
    },
    rimuoviOpzione(q, index) {
      console.log("memememememememe", q, index);
      q.steps.splice(index, 1);
      this.tempItem.tipo_form = "";
      this.tempItem.tipo_form = "Gradiente testuale";
      this.ad_ref++;
    },
    async resetPage() {
      this.currentPage = 1;
      this.items = [];
      this.surveyTypes = [];
      this.totalRows = 0;
      this.filter = null;
      this.loadingItems = false;
      this.loadingSurveyTypes = false;
      this.loadingKpis = false;
      this.loadingSaveData = false;

      await this.getSurveyTypes();
      await this.getCompanyKpi();
    },
    resetSurveySidebar() {
      this.tempItem = { ...this.modelItem };
      this.selectedSurveyTypes = [...(this.tempItem.id_tipi || [])];
    },
    openSurveySidebar(item) {
      this.tempItem = { ...item };

      this.tempItem.tipo_form = "Gradiente testuale";

      for (let q of this.tempItem.domande) {
        if (!q.steps) {
          q.steps = [];
        }
      }

      this.selectedSurveyTypes = [...(this.tempItem.id_tipi || [])];

      if (this.tempItem.domande.length) {
        for (let q of this.tempItem.domande) {
          q.editing = false;
        }
      }
    },
    openDeliverySidebar(item) {
      this.tempItem = { ...item };

      this.$root.$emit("bv::toggle::collapse", "sidebar-delivery");
    },

    companyKpiName(idKpi) {
      if (!idKpi) return " - ";
      if (!this.companyKpisList) return " - ";
      if (!this.companyKpisList.find((x) => x.value === idKpi)) return " - ";

      return this.companyKpisList.find((x) => x.value === idKpi).text;
    },

    handleQuestionCheckboxesChange(checkbox, q) {
      if (checkbox === "presente" && !q.nota_presente)
        q.nota_obbligatoria = false;
      if (checkbox === "obbligatoria" && q.nota_obbligatoria)
        q.nota_presente = true;
    },

    addNewQuestion() {
      const steps = this.useDefaultSteps
        ? JSON.parse(JSON.stringify(this.defaultSteps)) // Usa i valori di default se selezionato
        : {
            rateDa: "",
            t_labelDa: {
              "it-IT": "",
              "en-EN": "",
              "es-ES": "",
            },
            rateA: "",
            t_labelA: {
              "it-IT": "",
              "en-EN": "",
              "es-ES": "",
            },
          }; // Valori vuoti se non selezionato

      for (let q of this.tempItem.domande) {
        q.editing = false;
      }

      this.tempItem.domande.push({
        id: 0,

        id_kpi: 0,
        t_domanda: {},
        rateDa: 0,
        t_labelDa: {},
        rateA: 0,
        t_labelA: {},
        nota_presente: false,
        nota_obbligatoria: false,
        editing: true,
        id_survey: this.tempItem.id,
        ...steps,
      });
    },
    openQuestion(q) {
      q.editing = true;

      this.$forceUpdate();
    },
    deleteQuestion(index) {
      this.tempItem.domande.splice(index, 1);
    },
    confirmDeleteSurvey(item) {
      this.tempItem = { ...item };
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete this survey?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) this.deleteItem();
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    async deleteItem() {
      const service = new AxiosService("Survey/Delete");
      this.loadingDeleteItem = true;

      try {
        await service.delete(this.tempItem.id);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingDeleteItem = false;
        this.loadItems();
      }
    },
    async saveItem() {
      const service = this.tempItem.id
        ? new AxiosService("Survey/Put")
        : new AxiosService("Survey/Post");

      this.loadingSaveData = true;

      this.tempItem.id_company = this.currentCompany;
      this.tempItem.nome = this.tempItem.t_nome[this.view_lang];

      console.log("item to save ", this.tempItem);

      if (this.tempItem.domande.length) {
        for (let q of this.tempItem.domande) {
          q.domanda = q.t_domanda[this.view_lang];
          q.labelDa = q.t_labelDa[this.view_lang];
          q.labelA = q.t_labelA[this.view_lang];
        }
      }

      try {
        this.tempItem.id
          ? await service.update(this.tempItem)
          : await service.create(this.tempItem);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingSaveData = false;
        this.$root.$emit("bv::toggle::collapse", "sidebar-survey");
        this.loadItems();
      }
    },
    async getCompanyKpi() {
      if (!this.currentCompany) return;

      this.loadingKpis = true;
      this.companyKpisList.splice(0);
      let service = new AxiosService("emmd");

      try {
        const response = await service.readCustomEndpoint(
          "GlobalKpi?idCompany=" + this.currentCompany
        );
        this.companyKpisList = response.map((kpi) => {
          return {
            value: kpi.id,
            text: kpi.name,
          };
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingKpis = false;
      }
    },
    async getSurveyTypes() {
      const service = new AxiosService("Option/SurveyType");

      this.loadingSurveyTypes = true;
      this.surveyTypes = [];

      try {
        const response = await service.read();
        this.surveyTypes = response || [];

        if (this.surveyTypes.length > 0) {
          for (let type of this.surveyTypes) {
            type.icon = this.syrveyTypeIcons[type.text] || "asterisk";
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingSurveyTypes = false;
      }
    },
    async loadItems() {
      console.log("create session");
      const service = new AxiosService(
        "Survey/GetByCompany/" + this.$store.getters.currentCompany
      );
      this.resetPage();
      this.loadingItems = true;

      try {
        const response = await service.read();
        this.items = response || [];

        this.totalRows = this.items.length;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingItems = false;
      }
    },
    toggleSelection(value) {
      const index = this.selectedSurveyTypes.indexOf(value);
      if (index === -1) {
        this.selectedSurveyTypes.push(value); // Add if not already selected
      } else {
        this.selectedSurveyTypes.splice(index, 1); // Remove if already selected
      }
      this.tempItem.id_tipi = [...this.selectedSurveyTypes]; // Update tempItem
    },
  },
};
</script>

<template>
  <div class="content-wrapper">
    <h1>Surveys</h1>

    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>

        <b-col class="text-lg-right">
          <b-button
            :disabled="loadingItems || loadingSurveyTypes || loadingKpis"
            size="sm"
            class="mb-2"
            v-b-toggle.sidebar-survey
          >
            <b-icon icon="plus" aria-hidden="true"></b-icon>
            New Survey
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-overlay :show="loadingItems" rounded="sm">
        <b-table
          hover
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
        >
          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <b-icon-gear-fill></b-icon-gear-fill>
              </template>
              <b-dropdown-item
                href="#"
                v-b-toggle.sidebar-survey
                @click="openSurveySidebar(row.item)"
              >
                Edit
              </b-dropdown-item>

              <b-dropdown-item href="#" @click="openDeliverySidebar(row.item)">
                Deliveries
              </b-dropdown-item>
              <b-dropdown-item href="#">
                Copy
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="confirmDeleteSurvey(row.item)"
                >Delete
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template #cell(t_nome)="row">
            {{ row.item.t_nome[view_lang] }}
          </template>
          <template #cell(id_tipi)="row">
            <h2>
              <b-badge
                v-for="type in row.item.id_tipi"
                :key="type"
                variant="primary"
                class="mr-1"
              >
                <b-icon
                  :icon="
                    surveyTypes.find((s) => s.value === type).icon || 'asterisk'
                  "
                ></b-icon>

                {{ surveyTypes.find((s) => s.value === type).text }}
              </b-badge>
            </h2>
          </template>

          <template #cell(id_kpi_esecuzione)="row">
            {{ companyKpiName(row.item.id_kpi_esecuzione) }}
          </template>

          <template #cell(id_kpi_ricezione)="row">
            {{ companyKpiName(row.item.id_kpi_ricezione) }}
          </template>

          <template #cell(domande)="row">
            <div>
              <b-icon
                v-if="row.item.domande.length == 0"
                icon="slash-circle"
              ></b-icon>
              <span v-else>
                {{ row.item.domande.length }}
              </span>
            </div>
          </template>
        </b-table>
      </b-overlay>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="mt-4 mb-1"
      ></b-pagination>
    </b-card>

    <b-sidebar
      class="right-sidebar"
      id="sidebar-survey"
      right
      shadow
      lazy
      @hidden="resetSurveySidebar"
    >
      <div class="content-wrapper mt-2 bg-white h-100 py-4">
        <h4>
          {{ tempItem.id ? "Survey details" : "New survey" }}
        </h4>

        <!-- Survey Name -->
        <b-row class="mb-4">
          <b-col cols="12">
            <h5>Name</h5>
          </b-col>

          <b-col cols="12">
            <b-form-input v-model="tempItem.t_nome[view_lang]" />
            <LangOptions v-model="view_lang" :options="languages" />
          </b-col>
        </b-row>

        <!-- Survey Type Selection -->
        <b-row class="mb-4">
          <b-col cols="12">
            <h5>Survey Types</h5>
          </b-col>

          <b-col cols="3" v-for="s in surveyTypes" :key="s.value">
            <b-card
              :class="{
                'border-primary': selectedSurveyTypes.includes(s.value),
              }"
              @click="toggleSelection(s.value)"
              class=" cursor-pointer flex flex-column h-100"
            >
              <div class="h-100 d-flex flex-column flex-grow-1">
                <div class="flex justify-content-center mb-4">
                  <b-icon
                    :icon="s.icon || 'asterisk'"
                    class="h1  text-primary"
                  ></b-icon>
                </div>
                <b-card-body style="padding:0">
                  <div
                    class="h-100 d-flex flex-column justify-content-between flex-grow-1"
                  >
                    <div>
                      <b-card-title class="text-primary">{{
                        s.text
                      }}</b-card-title>
                      <b-card-text class="mb-4 text-primary">{{
                        s.description
                      }}</b-card-text>
                    </div>

                    <!-- Custom Checkbox Indicator -->
                    <div
                      class="h-100 d-flex justify-content-center align-items-end m-0 p-0"
                    >
                      <b-icon
                        v-if="selectedSurveyTypes.includes(s.value)"
                        icon="check-circle"
                        class="h3 m-0 text-primary"
                      ></b-icon>
                      <b-icon
                        v-else
                        icon="circle"
                        class="h3  m-0"
                        style="color:lightgray"
                      ></b-icon>
                    </div>
                  </div>
                </b-card-body>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <!-- Selezione KPIS -->
        <b-row class="mb-4">
          <b-col cols="12">
            <h5>KPIs</h5>
          </b-col>

          <b-col cols="12" md="6">
            <label for="execution-kpi">Accomplished survey done kpi</label>
            <b-form-select
              id="execution-kpi"
              v-model="tempItem.id_kpi_esecuzione"
              :options="companyKpisList"
            ></b-form-select>
          </b-col>

          <b-col cols="12" md="6">
            <label for="reception-kpi">Accomplished survey received kpi</label>
            <b-form-select
              id="reception-kpi"
              v-model="tempItem.id_kpi_ricezione"
              :options="companyKpisList"
            ></b-form-select>
          </b-col>
        </b-row>

        <!-- Questions -->
        <b-row v-if="tempItem.id">
          <b-col cols="12">
            <h5>Questions</h5>
          </b-col>

          <b-col cols="12">
            <div
              class="list-group-item p-0 border-0 bg-gray mb-3 d-flex align-items-center"
              v-for="(q, i) in tempItem.domande"
              :key="i"
            >
              <div v-if="q.editing" class="w-100 px-4 py-3 ">
                <b-row class="mb-3">
                  <div
                    class="w-100 flex justify-content-end align-items-center "
                  >
                    <b-button-close
                      class="mr-4"
                      @click="q.editing = false"
                    ></b-button-close>
                  </div>
                </b-row>
                <b-row class=" mb-3">
                  <b-col cols="12" class="mb-3">
                    <label :for="'domanda-' + i">Question</label>
                    <b-form-input
                      :id="'domanda-' + i"
                      v-model="q.t_domanda[view_lang]"
                    />
                    <LangOptions v-model="view_lang" :options="languages" />
                  </b-col>

                  <b-col cols="12" class="mb-3">
                    <label for="question-kpi">Single KPI value</label>
                    <b-form-select
                      id="question-kpi"
                      v-model="q.id_kpi"
                      :options="companyKpisList"
                    ></b-form-select>
                  </b-col>
                </b-row>

                <b-row class="mb-4">
                  <b-col>
                    <label for="use-default-values" class="m-0 mr-2 font-bold">
                      Scelta multipla
                    </label>
                    <b-form-checkbox
                      type="checkbox"
                      v-model="q.sceltaMultipla"
                    />
                  </b-col>
                </b-row>

                <b-row
                  :ref="ad_ref"
                  v-if="tempItem.tipo_form == 'Gradiente testuale'"
                  class="mb-4"
                >
                  <b-col cols="12">
                    <div
                      v-for="(opzione, index) in q.steps"
                      :key="index"
                      class="mb-3"
                    >
                      <b-row>
                        <b-col cols="6">
                          <label :for="'label-opzione-' + index"
                            >Option label</label
                          >
                          <b-form-input
                            :id="'label-opzione-' + index"
                            v-model="opzione.t_label[view_lang]"
                            placeholder="Insert label"
                          />
                          <LangOptions
                            v-model="view_lang"
                            :options="languages"
                          />
                        </b-col>

                        <b-col cols="3">
                          <label :for="'value-opzione-' + index">Value</label>
                          <b-form-input
                            :id="'value-opzione-' + index"
                            type="number"
                            v-model="opzione.value"
                            placeholder="Insert value"
                          />
                        </b-col>

                        <b-col cols="3" class="text-right">
                          <!-- Icona cestino grigia per rimuovere -->
                          <b-button
                            variant="link"
                            style="color: black; font-weight: bold; margin-top: 1rem"
                            @click="rimuoviOpzione(q, index)"
                          >
                            <b-icon icon="trash" class="mt-3"></b-icon>
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>

                    <b-button @click="aggiungiOpzione(q)">Add Option</b-button>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12" md="6">
                    <label for="nota_presente" class="font-bold">
                      Present note
                    </label>
                    <b-form-checkbox
                      id="nota_presente"
                      v-model="q.nota_presente"
                      @change="handleQuestionCheckboxesChange('presente', q)"
                      style="margin-left:2px"
                    ></b-form-checkbox>
                  </b-col>
                </b-row>

                <b-row v-if="q.nota_presente" class="mb-2">
                  <b-col>
                    <div
                      class="w-100 flex justify-content-start align-items-center"
                    >
                      <b-form-checkbox
                        id="nota_obbligatoria"
                        v-model="q.nota_obbligatoria"
                        @change="
                          handleQuestionCheckboxesChange('obbligatoria', q)
                        "
                        switch
                        size="lg"
                        class="mb-2"
                      ></b-form-checkbox>
                      <span class="ml-2"> Optional / Required</span>
                    </div>
                  </b-col>
                </b-row>

                <b-row v-if="q.nota_presente">
                  <b-col>
                    <b-form-textarea
                      v-model="q.textarea"
                      :placeholder="
                        q.nota_obbligatoria
                          ? 'Metti qui il testo per il commento obligatorio'
                          : 'Metti il testo della CTA di esempio per il testo facoltativo'
                      "
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
              </div>
              <div v-if="!q.editing" class="w-100 px-4 py-3">
                <b-row>
                  <b-col cols="12">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="w-100">{{ q.t_domanda[view_lang] }}</div>

                      <div
                        class="w-100 flex justify-content-end align-items-center "
                      >
                        <b-icon
                          icon="gear-fill"
                          class="pointer mr-2"
                          @click="openQuestion(q)"
                        ></b-icon>
                        <b-icon
                          v-if="tempItem.id"
                          icon="trash-fill"
                          class="pointer"
                          @click="deleteQuestion(i)"
                        ></b-icon>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>

          <b-col cols="12">
            <hr />
          </b-col>

          <b-col v-if="tempItem.id" cols="12">
            <!-- Sezione per configurare i valori di default -->
            <div class="list-group-item p-0 border-0 bg-gray px-4 py-2 ">
              <div class="w-100 flex justify-content-end align-items-center ">
                <label for="use-default-values" class="m-0 mr-2">
                  Use default steps
                </label>
                <b-form-checkbox
                  id="use-default-values"
                  type="checkbox"
                  v-model="useDefaultSteps"
                />
              </div>
              <div
                v-if="useDefaultSteps"
                class="w-100 flex justify-content-between align-items-top mt-3"
              >
                <label class="mr-2">
                  Rate form
                  <b-form-input type="number" v-model="defaultSteps.rateDa" />
                </label>
                <label class="mr-2">
                  Label from
                  <b-form-input
                    type="text"
                    v-model="defaultSteps.t_labelDa[view_lang]"
                  />
                  <LangOptions v-model="view_lang" :options="languages" />
                </label>
                <label class="mr-2">
                  Rate to
                  <b-form-input type="number" v-model="defaultSteps.rateA" />
                </label>
                <label class="mr-2">
                  Label to
                  <b-form-input
                    type="text"
                    v-model="defaultSteps.t_labelA[view_lang]"
                  />
                  <LangOptions v-model="view_lang" :options="languages" />
                </label>
              </div>
            </div>
          </b-col>

          <b-col v-if="tempItem.id" cols="12">
            <b-button
              squared
              size="lg"
              class="button-dashed big-button w-100 "
              @click="addNewQuestion"
              >Add question</b-button
            >
          </b-col>
        </b-row>

        <!-- Submit Button -->
        <b-button
          squared
          variant="primary"
          size="lg"
          class="big-button my-5"
          @click="saveItem"
          :disabled="loadingSaveData"
        >
          {{ loadingSaveData ? "Saving..." : "Save" }}
        </b-button>
      </div>
    </b-sidebar>

    <b-sidebar
      id="sidebar-delivery"
      class="right-sidebar"
      right
      shadow
      lazy
      @hidden="resetSurveySidebar"
    >
      <Deliveries :survey="tempItem" />
    </b-sidebar>
  </div>
</template>
